import { createWebHistory, createRouter } from "vue-router";
import form from "@/views/form.vue";
import store from '../store';
const routes = [
  {
    path: "/referral-form",
    name: "form",
    component: form,
  },
  {
    path: '/PageNotFound',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/PageNotFound.vue')
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;