import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/styles/custom.css';
import store from './store'
import axios from 'axios';
import VueCookies from 'vue3-cookies'
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import VueSignaturePad from 'vue-signature-pad';
import VOtpInput from "vue3-otp-input";

const app = createApp(App);

app.use(store)
app.use(Datetime)
app.use(VueSignaturePad);
app.use(VueCookies);
app.component('v-otp-input', VOtpInput);

let baseUrl="https://crm.property-pistol.in/public/";
if(process.env.NODE_ENV === 'production'){
  baseUrl = "https://crm.property-pistol.in/public/";
}
app.use(VueCookies, { expireTimes: "30d", path: "/", domain: "", secure: true, sameSite: "None"});
axios.defaults.baseURL = baseUrl;
axios.defaults.headers = {
  'Content-Type': 'application/json',
  'ngrok-skip-browser-warning': true
}


router.beforeEach((to, from, next) => {
  if(to.name === undefined){
    next({ name: "form" });
  } else {
    next()
  }
})
app.config.globalProperties.$axios = axios;
app.config.productionTip = false

app.use(router).mount('#app')
