<template>
  <div>
      <router-view/>
      <div class="loader-wrapper"  v-show="isLoading">
        <div class="loader">Loading...</div>
      </div>
  </div>
</template>
<script>
export default {
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    }
  }
}
</script>
<style scoped>
.loader-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999!important;
  overflow: hidden;
  top: 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f5f5f5;
}
 .loader-wrapper{
    background: #f5f5f5;
    background-size: cover;
    height: 100vh;
  }
  .loader-wrapper:after {
  content: "";
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  animation: slide 1s infinite;
  background: -moz-linear-gradient( left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
 /* FF3.6+ */
  background: -webkit-gradient( linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.8)), color-stop(99%, rgba(128, 186, 232, 0)), color-stop(100%, rgba(125, 185, 232, 0)));
 /* Chrome,Safari4+ */
  background: -webkit-linear-gradient( left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
 /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient( left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
 /* Opera 11.10+ */
  background: -ms-linear-gradient( left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
 /* IE10+ */
  background: linear-gradient( to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1);
 /* IE6-9 */
}
@keyframes slide {
  0% {
      transform: translateX(-100%);
 }
  100% {
      transform: translateX(100%);
 }
}
  .loader-wrapper .loader,   .loader-wrapper .loader:before, .loader-wrapper .loader:after {
    background: #f58734;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: .7em;
    height: 4em;
  }
  .loader-wrapper .loader {
    color: #f58734;
    text-indent: -9999em;
    margin: 0 auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    display: table;
    z-index: 9999;
    top: 50%;
  }
  .loader-wrapper .loader:before,
  .loader-wrapper  .loader:after {
    position: absolute;
    top: 0;
    content: '';
  }
  .loader-wrapper .loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader-wrapper  .loader:after {
    left: 1.5em;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
</style>